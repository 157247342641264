exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-allergens-tsx": () => import("./../../../src/pages/allergens.tsx" /* webpackChunkName: "component---src-pages-allergens-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-franchising-tsx": () => import("./../../../src/pages/franchising.tsx" /* webpackChunkName: "component---src-pages-franchising-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ingredients-tsx": () => import("./../../../src/pages/ingredients.tsx" /* webpackChunkName: "component---src-pages-ingredients-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

